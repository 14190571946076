<template>
  <footer class="bg-[#303030]">
    <div class="max-w-7xl sm:px-6 lg:px-8 px-4 py-12 mx-auto overflow-hidden">
      <nav class="flex flex-wrap justify-center -mx-5 -my-2" aria-label="Footer">
        <p class="px-5 py-2 text-sm text-center text-gray-200 mr-16">&copy; {{ year }} personalforum leipzig</p>
        <div v-for="item in navigation" :key="item.name" class="px-5 py-2">
          <a :href="item.href" class="hover:text-gray-200 text-sm text-gray-200" v-if="item.external">
            {{ item.name }}
          </a>
          <Link :href="item.href" class="hover:text-gray-200 text-sm text-gray-300" v-else>
            {{ item.name }}
          </Link>
        </div>
      </nav>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { computed } from "vue"
import { Link, usePage } from "@inertiajs/inertia-vue3"

const { props: pageProps } = usePage()

const navigation = computed(() => [
  { name: "Impressum", href: "https://leipziger-personalforum.com/eine-seite/impressum/", external: true },
  { name: "Datenschutz", href: "https://leipziger-personalforum.com/eine-seite/impressum/datenschutzerklaerung/", external: true },
  { name: "Stellen schalten", href: "/arbeitgeber", external: false },
])

const year = new Date().getFullYear()
</script>
