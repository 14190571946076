<template>
  <nav class="bg-white py-10">
    <div class="mx-auto px-2 sm:px-6 lg:px-8">
      <div class="flex flex-shrink-0 items-center text-lg font-semibold">
        <Link href="https://leipziger-personalforum.com/" class="transition-all hover:brightness-125 hover:contrast-125 flex gap-5 items-center">
          <img :src="logo" class="h-16 w-auto" :alt="settings.name" />

          <span class="font-bold text-4xl">LEIPZIGER PERSONALFORUM</span>
        </Link>
      </div>
      <div class="mr-8 flex flex-wrap mt-5">
        <Link href="/" class="font-bold px-5 py-2 uppercase" :class="{'bg-primary text-white': !isActive('/arbeitgeber')}"> Jobs</Link>
        <Link href="/arbeitgeber" class="font-bold px-5 py-2 uppercase" :class="{'bg-primary text-white': isActive('/arbeitgeber')}">
          Stelle schalten
        </Link>
        <a v-for="link in links" :href="link.link" class="font-bold px-5 py-2 uppercase hover:bg-primary hover:text-white">{{ link.text }}</a>
      </div>
    </div>
    <div class="absolute inset-y-0 right-0 hidden items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 md:flex">
    </div>
  </nav>
</template>

<script lang="ts" setup>
import { Link } from "@inertiajs/inertia-vue3"
import { Button } from "@/elements"
import settings from "@/generated/settings.json"
import { logo } from "@/utils/logos"

const links = [
  {
    text: "Programm 2023",
    link: "https://leipziger-personalforum.com/programm-2023/",
  },
  {
    text: "Partner und Sponsoren",
    link: "https://leipziger-personalforum.com/partner-und-sponsoren/",
  },
  {
    text: "Rückblick Programm 2022",
    link: "https://leipziger-personalforum.com/programm-2022/",
  },
  {
    text: "Personal-Blog",
    link: "https://leipziger-personalforum.com/blog/",
  },
  {
    text: "HR-Termine",
    link: "https://leipziger-personalforum.com/hr-termine/",
  },
  {
    text: "Netzwerktreffen Personal",
    link: "https://leipziger-personalforum.com/netzwerktreffen-personal/",
  },
  {
    text: "Impressum",
    link: "https://leipziger-personalforum.com/eine-seite/impressum/",
  },
]

const isActive = (path: string) => {
  return window && path === window.location.pathname
}
// const activeClasses = "ring-2 ring-offset-2 ring-offset-gray-800 ring-white"
</script>
